.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: calc(var(--mui-spacing) * 8);
  padding-bottom: calc(var(--mui-spacing) * 8);
}

.linksContainer {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--mui-spacing);
  margin-left: var(--mui-spacing);
}

.links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--mui-spacing);
}

@media (--mui-breakpoint-sm-up) {
  .root {
    padding-left: 15%;
    padding-right: 15%;
  }
}
